/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import img1 from "../assets/boat-purple.jpg";

const Container = styled.div`
  width: 80vw;
  min-height: 150vh;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 5rem;
  position: relative;

  background-color: ${(props) => `rgba(${props.theme.textRgba},0.8)`};
  color: ${(props) => props.theme.text};

  background-image: url(${img1});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 48em) {
    width: 100vw;
    padding: 0;
    min-height: 200vh;


  }

  @media (max-width: 25em) {
    min-height: 250vh;

  }

`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-align: center;
  font-weight: 300;
  margin-bottom: 2rem;
  text-transform: uppercase;
  width: 100%;
`;

const SubTitle = styled.h1`
  font-size: ${(props) => props.theme.fontlg};
  text-align: left;
  font-weight: 300;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 25em) {
    font-size: ${(props) => props.theme.fontmd};

    width: 90%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => `rgba(${props.theme.bodyRgba},0.7)`};
  z-index: 1;
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;

  @media (max-width: 25em) {
    min-height: 100vh;
    height: 100%;

  }
`;

const Text = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  text-align: left;
  font-weight: 300;

  width: 80%;
  margin: 0 auto;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontsm};
  }

  li {
    margin-left: 2rem;
  }

  @media (max-width: 25em) {
    font-size: ${(props) => props.theme.fontsm};
    padding: 0.5rem 0;
    width: 90%;
  }
`;

const Vision = () => {

  return (
    <Container data-scroll-section id="vision">
      <Overlay />
      <Box
        data-scroll
        data-scroll-speed={-1}
        data-scroll-direction={"verticle"}
      >
        <Title>Vision</Title>

        <SubTitle>Community Goals</SubTitle>
        <br />

        <Text>
        We pursue being pioneers in the World of Web 3, creating a platform for digital artists 
        to share their art and another venue for Open discussions, 
        with a strong Community as a Foundation.
        That’s why the community has an indispensable place on our upcoming journey towards a new Future.
        </Text>

        <Text>
        We want to create a Community with transparency and open communication as its core values, 
        to provide a welcoming and engaging platform that is fertilized soil for thought-provoking ideas.
        </Text>

       
        
        
        <SubTitle>Web3</SubTitle>
        
        
        <br />
        <Text>
        The Web3 has a huge amount of hidden potential that has yet to be unearthed, and our first step towards this goal 
        is to create our own modified Smart Contract. 
        We think the Smart Contract is a field with many opportunities.
        </Text>
        <br />
        
        
        <SubTitle>A platform for digital artists</SubTitle>
        
        
        <Text>
        We believe on an artistic level, NFT offers a lot of potential, especially its future potential is tremendous.
        Digital Art has given the notion of “Art” a new meaning and almost a new definition. 
        And by creating a platform for the Space that is supportive of contemporary artists,
        particularly the smaller ones with neither Capital nor Attention, we can lay a foundation for future Generations.
        </Text>
        <br />
        <Text>
        A platform for open and honest Discussions without fearing censorship
        (Topics range from global economy to mental health) promise that will aim to
        continuously improve and become better and hopefully that we will exceed your expectations.
        </Text>
        
        <Text>
        One of the most important things for us is creating our own Platform. 
        Having our own Platform allows for open discussions where an 
        honest and genuine Opinion gets valued above everything else. 
        History has proven how important healthy, uncensored talks are, in particular, to uncover the truth.
        </Text>
        <br />
        <Text>
        
        </Text> <br/>
        
          <br />
        <Text>
        Our Collection has a total of 500 NFTs
        The NFTs are ready for Mint (MINT is LIVE).<br />
          <ul>
            If you like what we do, Welcome!
            If not, have a nice Day and Ciao!
           
          
          
          </ul>
        </Text><br />
        <Text>
        All those who minted our NFT will get a WL Spot on our upcoming main collection as a Free Mint.
        </Text>
        <br/>
        <Text>The Future is in your Hands. Welcome to the Odessey of the Unknown Men.
</Text>

      </Box>
    </Container>
  );
};

export default Vision;
