import React from "react";
import styled from "styled-components";
import img1 from "../assets/boat-yellow.jpg";

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  @media (max-width: 64em) {
    padding-bottom: 5rem;
  }
`;

const Box = styled.div`
  width: 80%;
  height: 80vh;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 64em) {
    flex-direction: column-reverse;
    min-height: 100vh;
    height: auto;
  }
`;

const Left = styled.div`
  width: 40%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 64em) {
    width: 100%;
  }
`;

const Right = styled.div`
  height: 100%;
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 64em) {
    width: 100%;
  }
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontlg};
  text-align: center;
  font-weight: 300;
  margin-bottom: 1rem;
  text-transform: uppercase;
  align-self: flex-start;
`;

const Text = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  text-align: left;
  font-weight: 300;

  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const Start = () => {
  return (
    <Container data-scroll-section id="start">
      <Box>
        <Right data-scroll data-scroll-speed="-1">
          <Title>Start</Title>
          <Text>
            This NFT is just the first step on our Journey. And has only three
            main uses:
          </Text>
          <br />

          <Text>
            &nbsp;&nbsp;1. Raising capital to realize the first part of the
            Journey. <br />
            &nbsp;&nbsp;2. Building the Core of our Community (In return) sharing
            knowledge on how to move during Recession while evading
            possible danger and pitfalls, being aware of opportunities and
            taking full advantage of them.
            <br />
            &nbsp;&nbsp;3. Rewarding those that believe in us so early on this
            Long Journey.
            At the same time, you will get shares of the Investments we will take.
            <br />
          </Text>

          <br />

          <Text>
            The Mint price is 0.075 ETH. <br />
            Mint is Live now! Claim your Token!
          </Text>
          <br />
          <Title>why?</Title>

          <Text>
          Our goal is to achieve one of our biggest dreams, a "real" fantasy world. We see the current
NFT-Space as the most significant opportunity to create and generate extraordinary ideas.<br /><br />
Sadly the true potential of NFT has been diluted by
all of the arguable idiocy and the potential for scammers to create false promises to innocent
people.<br /><br />
Regardless of such dilution, there remains a considerable opportunity that we believe we
can utilize and succeed from.<br /><br />
The real potential of the space is yet to be seen.<br /><br />
But it's time for a change, and we are ready to make that happen.
          </Text>
        </Right>
        <Left data-scroll data-scroll-direction="horizontal">
          <img
            data-scroll
            data-scroll-speed="1"
            src={img1}
            alt="Unknown Men NFT"
          />
        </Left>
      </Box>
    </Container>
  );
};

export default Start;
